/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


var d = new Date();
var tos = d.getTimezoneOffset();
var n = tos < 0 ? "+" + ("0" + (-1 * parseInt(tos / 60))).slice(-2) + ':' + ((0 - tos) % 60) : "-" + ("0" + (parseInt(tos / 60))).slice(-2) + ':' + (tos % 60);
document.cookie = "timeOffset=" + n + ";";

var urlPath = location.protocol + "//" + location.hostname + (location.port && ":" + location.port) + "/";
urlPath = (urlPath == 'http://homestarpro.us-west-2.elasticbeanstalk.com/') ? (urlPath + 'product/public/') : urlPath; //extra for Elastic
var baseUrl = urlPath;
var baseUrlAdmin = urlPath;

var rowPerPageCOunt = 10;
var pageNumberMaxSize = 5;

setTimeout(function() {
    $(".alert").hide('blind', {}, 500)
}, 10000);